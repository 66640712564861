














import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "settings-navigation",
  data() {
    return {
      links: [
        {
          label: "Types of Appointments",
          href: "/nomenclators/appointment_types",
        },
        {
          label: "Subtypes of Procedures",
          href: "/nomenclators/procedures-subtypes",
        },
        {
          label: "Procedures status",
          href: "/nomenclators/procedure_status",
        },
        {
          label: "Leads status",
          href: "/nomenclators/lead_status",
        },
        {
          label: "Form Procedures",
          href: "/nomenclators/procedure_form",
        },
        {
          label: "Medical locations",
          href: "/nomenclators/medical-locations",
        },
        {
          label: "Occupations",
          href: "/nomenclators/ocupations",
        },
        {
          label: "Accesories",
          href: "/nomenclators/accessories",
        },
        {
          label: "Sources",
          href: "/nomenclators/references",
        },
      ],
    };
  },
  mounted() {
    this.links[7].label = this.dataCRM.nomenclators
      ? this.dataCRM.nomenclators.accessories
      : "Accessories";
  },
  computed: {
    ...mapState(["dataCRM"]),
  },
});
